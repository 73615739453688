<template>
  <nav v-if="$store.getters.logged && $store.state.tavoloGiurato == ''" class="navbar fixed-bottom bgAlma justify-content-center">

    <div class="row w-100">
      <div class="col text-center">
        <a href="#/home"><i class="bi bi-house-door-fill text-white fs-1"></i></a>
      </div>
      <div class="col text-center">
        <a href="#/profilo" @click="$route.path =='/profilo'? reload() : ''"><i class="bi bi-person-circle text-white fs-1"></i></a>
      </div>
      <div v-if="abilitaPorte" class="col text-center">
        <a href="/#/Porte"><i class="bi bi-door-open-fill text-white fs-1"></i></a>
      </div>
      <div class="col text-center">
        <a @click="openCloseMenu"><i class="bi bi-list text-white fs-1"></i></a>
      </div>
    </div>

    <!-- STRUTTURA A 5 SLOT NON EQUIVALENTI-->
    <!-- <div class="row w-100">
      <div class="col">
        <div class="row">
          <div class="col-6 text-center">
            <a href="#"><i class="bi bi-house-door-fill text-white fs-1"></i></a>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
      <div class="col-4 text-center">
        <a href="#"><i class="bi bi-person-circle text-white fs-1"></i></a>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-6 text-center">
            <a href="/#/Porte"><i class="bi bi-door-open-fill text-white fs-1"></i></a>
          </div>
          <div class="col-6 text-center">
            <a @click="openMenu">
              <i class="bi bi-list text-white fs-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div> -->
  </nav>
</template>

<script>
  export default {
    name: 'bottomNavBar',
    data() {
      return {
        openMenu: false
      };
    },
    computed: {
      abilitaPorte: function() {
        if(this.$store.state.userprofile.Abilitazioni_Webapp__pc 
          && this.$store.state.userprofile.Abilitazioni_Webapp__pc.includes('Apertura Porte')) return true
        return false
      }
    },
    created: function() {},
    methods: {
      openCloseMenu() { // chiama l'offcanvas in NavBar.vue
        const menuCanvas = document.querySelector('#offcanvasNavbar');
        if(!this.openMenu) {
          menuCanvas.className += ' show'
          menuCanvas.style = 'visibility: visible;'
        } else if(this.openMenu) {
          menuCanvas.className = 'offcanvas offcanvas-end'
          menuCanvas.style = 'visibility: hidden;'
        }
        this.openMenu = !this.openMenu
      },
      reload() { location.reload() },
    }
  }
</script>