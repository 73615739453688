import Vue                  from 'vue'
import VueRouter            from 'vue-router'
import LogIn                from '../views/Login.vue'
import Home                 from '../views/Home.vue'
import Presenze             from '../views/Presenze.vue'
import Tesi                 from '../views/Tesi.vue'
import Esami                from '../views/Esami.vue'
import LoginEsterni         from '../views/LoginEsterni.vue'
import Porte                from '../views/Porte.vue'
import Profilo              from '../views/Profilo.vue'
import InvioEmailRecovery   from '../views/InvioEmailRecovery.vue'
import Recovery             from '../views/Recovery.vue'



Vue.use(VueRouter)

const routes = [
    { path: '/',                    name: 'Login',                  component: LogIn },
    { path: '/login',               name: 'Login',                  component: LogIn },
    { path: '/home',                name: 'Home',                   component: Home },
    { path: '/presenze',            name: 'Presenze',               component: Presenze},
    { path: '/tesi',                name: 'Tesi',                   component: Tesi },
    { path: '/esami',               name: 'Esami',                  component: Esami},
    { path: '/loginEsterni',        name: 'LoginEsterni',           component: LoginEsterni},
    { path: '/porte',               name: 'Porte',                  component: Porte},
    { path: '/profilo',             name: 'Profilo',                component: Profilo},
    { path: '/ierecovery',          name: 'InvioEmailRecovery',     component: InvioEmailRecovery },
    { path: '/recovery',            name: 'Recovery',               component: Recovery },
]

const router = new VueRouter({routes})

export default router