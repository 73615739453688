<template>
    <div v-if="$store.getters.logged">
        <div class="position-absolute ms-3 mt-0">
            <a href="/#/home" class="bi bi-arrow-left-circle-fill fs-2"></a>
        </div>

        <div class="container text-center col-lg-5">

            <div class="row mb-4">
              <h1 class="fw-bold">Pulsanti apri porte</h1>
            </div>

            <div v-if="attivaGPS" class="alert alert-danger mx-4 text-center">
                Per aprire le porte <br>è necessario attivare il GPS
            </div>
            <div v-else>
                <div v-if="myDistCancello > 50 && myDistSpogliatoio > 50" class="alert alert-danger mx-4 text-center mb-4">
                    Sei troppo lontano dalle porte.<br>Devi essere a meno di 50m.
                </div>
                <div class="row mx-5 mb-3">
                    <button id="Cancello" :class="disabilitaCancello ? 'btn-lg mb-2 py-4 bg-secondary bg-opacity-25':'btn-lg mb-2 py-4'"
                    @click="openPorta('Cancello')" :disabled="disabilitaCancello">Cancello</button>
                    <h6>Dist: {{ myDistCancello }}m</h6>
                </div>
                <div class="row mx-5">
                    <button id="Spogliatoio" :class="disabilitaSpogliatoio ? 'btn-lg mb-2 py-4 bg-secondary bg-opacity-25':'btn-lg mb-2 py-4'"
                    @click="openPorta('Spogliatoio')" :disabled="disabilitaSpogliatoio">Spogliatoio</button>
                    <h6>Dist: {{ myDistSpogliatoio }}m</h6>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    name: 'Porte',
    components:{},
    data() {
        return {
            ufficio_lat: 44.6357444,
            ufficio_lng: 10.8994511,

            // cancello_lat: 44.9309499, // 44.9301581,10.3758494,21z
            // cancello_lng: 10.3776571,
            cancello_lat: 44.93015234362793, // 44.9301581,10.3758494,21z
            cancello_lng: 10.37579272874666,
            myDistCancello: 0,
            disabilitaCancello: true,

            // spogliatoi_lat: 44.930178, //44.930178,10.3762582,21z
            // spogliatoi_lng: 10.3762582,
            spogliatoi_lat: 44.93103810124558, //44.930178,10.3762582,21z
            spogliatoi_lng: 10.377292921776302,
            myDistSpogliatoio: 0,
            disabilitaSpogliatoio: true,

            myCoordinate: 0,

            chiamaDoLoc: true,
            attivaGPS: false,
        };
    },
    computed: {},
    created(){
        if (navigator.geolocation) this.doJob();
        else alert("Geolocation API is not supported in your browser.")
    },
    methods:{
        async doJob() {
            while(this.chiamaDoLoc) {
                await this.doLoc();
                await this.doPause(1000);
            }
        },
        doLoc() {
            var self = this;
            self.chiamaDoLoc = false
            return new Promise( function(resolve, reject) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    console.log('position.coords.accuracy', position.coords.accuracy);
                    console.log('position.coords.latitude', position.coords.latitude);
                    console.log('position.coords.longitude', position.coords.longitude);
                    let msg="lat:"+position.coords.latitude+" long:"+position.coords.longitude+" acc:"+position.coords.accuracy;
                    console.log(msg)

                    let d=self.distance(self.ufficio_lat,self.ufficio_lng, position.coords.latitude, position.coords.longitude);
                    if (d<=20) {
                        console.log('<b>sei in ufficio!'+d+'</b><br/>')
                    }

                    d=self.distance(self.cancello_lat,self.cancello_lng, position.coords.latitude, position.coords.longitude);
                    if (d<=50) {
                        console.log('<b>sei al cancello d\'ingresso!'+d+'</b><br/>')
                        self.disabilitaCancello = false
                    }
                    self.myDistCancello = d.toFixed(2)

                    d=self.distance(self.spogliatoi_lat,self.spogliatoi_lng, position.coords.latitude, position.coords.longitude);
                    if (d<=50) {
                        console.log('<b>sei dagli spogliatoi!'+d+'</b><br/>')
                        self.disabilitaSpogliatoio = false
                    }
                    self.myDistSpogliatoio = d.toFixed(2)
                    self.myCoordinate="lat:"+position.coords.latitude+" long:"+position.coords.longitude+" acc:"+position.coords.accuracy;

                    resolve();
                },
                function error(msg) {
                    console.log('error: '+msg);
                    self.attivaGPS = true
                    reject(msg);
                },
                { timeout:20000, enableHighAccuracy: true }
                )
            })
        },
        doPause(sleep) {
            return new Promise( function(resolve) {
                setTimeout(function() {
                    resolve();
                }, sleep)
            });
        },
        distance (lat1, lng1, lat2, lng2) {
            var R = 6371e3; // metres
            var phi1 = lat1 * Math.PI / 180; // phi1,phi2 in radians
            var phi2 = lat2 * Math.PI / 180;
            var deltaphi = (lat2 - lat1) * Math.PI / 180;
            var deltalambda = (lng2 - lng1) * Math.PI / 180;
            
            var a = Math.sin(deltaphi / 2) * Math.sin(deltaphi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltalambda / 2) * Math.sin(deltalambda / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            
            var d = R * c; // in metres
            
            return d ; // in km
        }, 
        openPorta(porta) {
            console.log('porta da aprire: '+porta)
            if(porta == 'Cancello') this.disabilitaCancello = true
            if(porta == 'Spogliatoio') this.disabilitaSpogliatoio = true
            this.$store.dispatch('saveApriPorta', {porta: porta})
            .then(resp => {
                console.log('saveApriPorta resp',resp)
                alert('Comando di apertura inviato. Se la porta non dovesse aprirsi contattare la segreteria.')
                if(porta == 'Cancello') this.disabilitaCancello = false
                if(porta == 'Spogliatoio') this.disabilitaSpogliatoio = false
            })
        }
    }
}
</script>
