<template>
    <div v-if="$store.getters.logged">
        <div class="position-absolute ms-3 mt-0">
            <a href="/#/home" class="bi bi-arrow-left-circle-fill fs-2"></a>
        </div>
        <div class="container text-center col-lg-6">
            <div class="row">
                <!-- <div class="col-1 ms-0"><span class="bi bi-arrow-left-circle-fill fs-2"></span></div>
                <div class="col-11"><h1 class="fw-bold">Elenco Studenti</h1></div> -->
                <h1 class="fw-bold">Elenco Studenti</h1>
            </div>
            <div class="row mx-2 my-1">
                <select class="form-select rounded-pill" v-model="selectedOptionCorso" v-on:change="generateSezione()">
                    <option value="" disabled>Seleziona corso</option>
                    <option v-for="corso in corsi" v-bind:key="corso.id">{{ corso.Name }}</option>
                </select>
            </div>
            <div class="row mx-2 my-1">
                <select class="form-select rounded-pill" v-model="selectedOptionSezione" v-on:change="generatePresenze()">
                    <option value="" disabled>Seleziona sezione</option>
                    <option v-for="sezione in sezioni" v-bind:key="sezione">{{ sezione }}</option>
                </select>
            </div>
            <div class="row mx-2 my-1">
                <select class="form-select rounded-pill" v-model="selectedOptionAula" v-on:change="generatePresenze()">
                    <option value="" disabled>Seleziona aula</option>
                    <option v-for="aula in aule" v-bind:key="aula.id">{{ aula.Name }}</option>
                </select>
            </div>
            <div class="row">
                <a v-if="registrato" style="color:red; font-size: 2vh;">Assenze già registrate</a>
            </div>
    
            <table class="table table-sm mt-2">
                <thead>
                    <tr>
                        <th scope="col"><h3 class="fw-bold mb-0 text-start">Studente</h3></th>
                        <th scope="col"><h3 class="fw-bold mb-0">Presenza</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p, index) in arraypresenze" :key="index">
                        <th class="text-start">{{ p.nome }}</th>
                        <td>
                            <span class="bi bi-circle-fill" :class="{ 'text-danger': !p.presente, 'text-success': p.presente}"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button class="mb-3 mx-auto" v-if="arraypresenze.length>0 && !registrato" @click="registraAssenze()">Registra Assenze</button>
    
        </div>
    </div>
</template>
  
<script>
import apiclient from '../apiclient.js'

export default {
    name: 'Presenze',
    components:{},
    data() {
        return {
            corsi: [],
            aule: [],
            sezioni: [],
            studenti: [],
            presenti: [],
            // presenze: new Map(),
            arraypresenze: [],
            selectedOptionCorso: '',
            selectedOptionSezione: '',
            selectedOptionAula: '',
            corsoid: '',
            aulaid: '',
            registrato: false,
            students: []     
        };
    },
    created: function() {
        this.getCorso();
    },
    methods:{
        getCorso:function(){
            var self=this;
            console.log('getCorso presenze');
            this.$store.dispatch('getCorso').then(resp => {
                console.log('corsi: ',resp);
                self.corsi=resp;
                self.getAula();
            })
            // console.log("GET CORSO");
            // var self=this;
            // var data={callname:'getCorso', username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
            // apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
            //     console.log("CORSI ==> ", resp);
            //     self.corsi=resp;
            // });
        },
  
        //viene chiamato dopo che è stato selezionato un corso da cui prende il numero delle sezioni e aggiunge n sezioni all'array 'sezioni'.
        //dopo di che se sono già stati selzionate le opzioni di tutte e tre le pick list chiama generaPresenze.
        generateSezione:async function(){
            this.sezioni=[];
            let index;
            //console.log("SELEZIONATO: ", this.selectedOptionCorso)
            //console.log(typeof(this.selectedOptionCorso));
            for(let i=0; i< this.corsi.length; i++){
                if(this.corsi[i].Name == this.selectedOptionCorso){
                    index=i;
                    this.corsoid=this.corsi[i].Id;
                }
            }
        /*   for(let i=0; i<this.corsi.length; i++){
                if(this.corsi[i].Corso__r.Key_Corso__c == this.corsoid)
                    index=i;
            }*/
            console.log("INDICE ==> ", index)
            //console.log("CORSI ==> ", this.corsi);
            const startCharCode = 'A'.charCodeAt(0);
            //console.log("Numero sezioni: ", this.corsi[index].Numero_sezioni__c);
            console.log("NUMERO SEZIONI ",this.corsi[index].Numero_sezioni__c)
            for(let i=0; i<this.corsi[index].Numero_sezioni__c; i++)
                this.sezioni.push(String.fromCharCode(startCharCode+i));
            //console.log("SEZIONI A==> ",this.sezioni)
            if(this.selectedOptionAula && this.selectedOptionSezione && this.sezioni.includes(this.selectedOptionSezione)){
                this.generatePresenze();
            }
        },
  
        getAula:function(){
            console.log("GET AULA");
            var self=this;
            var data={callname:'getAula', username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
            apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
                console.log("AULE ==> ", resp);
                self.aule=resp;
            });
        },
  
        getStudenti:async function(){
            await this.checkRegistrato();
            console.log("GET STUDENTI");
            var self=this;
            var data={callname:'getStudenti',corsoid:this.corsoid, sezione:this.selectedOptionSezione, username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
            
            return new Promise(function(resolve){
                apiclient.getcandidatura( self.$store.state.connection, data).then( function(resp) {
                    console.log("STUDENTI ==> ", resp);
                    self.studenti=resp;
                    return resolve();   
                });
            })
        },
  
        //crea una mappa temporanea in cui prima aggiunge tutti gli studenti e dopo aggiunge i presenti (possono esserci presenti non appartenenti
        //all'elenco dei presenti). essendo una mappa quando viene chiamato il set su una key già esistente viene aggiornato il valore dell'elemento
        //già presente nella mappa (quindi non viene aggiunto un nuovo elemento), per questo gli oggetti 'presenza' non vengono pushati direttamente
        //su 'arraypresenze'.
        generatePresenze:async function(){
            let mappaStudenti = new Map();

            if(this.selectedOptionAula && this.selectedOptionSezione && this.selectedOptionCorso){
                console.log("GENERATE PRESENZE");
                this.arraypresenze=[];
                await this.getStudenti();
                await this.getPresenze();
                if(this.studenti != null){
                    for(let s of this.studenti){
                        let presenza = { id:s.Account.Id, nome: s.Account.Name, presente: false }
                        mappaStudenti.set(s.Account.Id, presenza);
                    }
                }

                if(this.presenti!=null){
                    for(let p of this.presenti){
                    let presenza = { id:p.Account__r.Id, nome: p.Account__r.Name, presente: true }
                    mappaStudenti.set(p.Account__r.Id, presenza);
                    }
                }

                //this.presenze = mappaStudenti;
                let i=0;
                for(let p of mappaStudenti){
                    this.arraypresenze[i]=p[1];
                    i++;
                }
                this.arraypresenze.sort(this.confrontaStudenti)
                //console.log("MAPPA PRESENZE ",this.presenze);
                console.log("ARRAY PRESENZE ",this.arraypresenze);
            }
        },

        //funzione di supporto per la funzione di sorting
        confrontaStudenti:function(a, b){
            const nomeA=a.nome.toUpperCase();
            const nomeB=b.nome.toUpperCase();
            if(nomeA < nomeB)
                return -1
            if(nomeA > nomeB)
                return 1
            return 0;
        },

        getPresenze:function(){
            console.log("GET PRESENZE");
            var self=this;
            var data={callname:'getPresenze', aulaid:this.aulaid, sezione:this.selectedOptionSezione, corsoid:this.corsoid, username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
            
            return new Promise(function(resolve){
                apiclient.getcandidatura( self.$store.state.connection, data).then( function(resp) {
                    console.log("STUDENTI PRESENTI ==> ", resp);
                    self.presenti=resp;
                    return resolve();   
                });
            })
        },

        //dopo la getcandidatura chiama checkRegistrato per mostrare che le assenze sono già state registrate
        registraAssenze:function(){
            var self=this;
            console.log("Registrazione assenze");
            var data={callname:'registraAssenze', assenze: this.arraypresenze, aulaid: this.aulaid, username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
            apiclient.getcandidatura( this.$store.state.connection, data).then( function(resp) {
                console.log("RISPOSTA ==> ", resp);
                self.checkRegistrato();
                alert(resp);
            });
            
        },

        //controlla se le assenze sono state registrate nell'arco di un'ora
        checkRegistrato:function(){
            for(let aula of this.aule){
                if(aula.Name == this.selectedOptionAula)
                    this.aulaid = aula.Id;
            }

            console.log("Controllo se le assenze sono già registrate...");
            var self=this;
            var data={callname:'checkRegistrato', aulaid: this.aulaid, username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata};
            return new Promise(function(resolve){
                apiclient.getcandidatura( self.$store.state.connection, data).then( function(resp) {
                    console.log("RISPOSTA ==> ", resp);
                    if(resp == 'registrato')
                        self.registrato = true;
                    else
                        self.registrato = false;
                    //console.log("246 ",self.registrato);
                    return resolve();
                });
            })
        }
    }
}
</script>