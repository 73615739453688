<template>
  <div>
    <NavBar/>
    <div id="app">
      <router-view/>
    </div>
    <bottomNavBar/>
  </div>
</template>

<script>
import NavBar from '../src/components/NavBar.vue';
import bottomNavBar from '../src/components/bottomNavBar.vue';
  export default {
    name: 'app',
    components: {
      NavBar,
      bottomNavBar
    },

    created: function() {
      this.$store.commit('GET_USER_DATA')
    }
  }
</script>

<style>
/* @import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800'); */
:root{
  /* --font: 'Montserrat'; */
}

input{
  height: 5vh;
  width: 100%;
  border-radius: 10px;
  padding-left: 5%;
  border: 1px solid grey;
}

/* button{ border-radius: 10px; } */
</style>