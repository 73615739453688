<template>
    <div class="container col-lg-6">
        <div class="row text-start px-4 pb-4">
            <div class="form-group">
                <label class="pb-2">Password precedente</label>
                <div class="input-group">
                    <input v-model="user.pwdold" 
                        :class="!checkPwdOld?'is-invalid':'is-valid'"
                        :type="reveal ? 'text' : 'password'" class="form-control" 
                        placeholder="Password attuale"/>
                    <span :class="!reveal ? 'input-group-text bi bi-eye-fill': 'input-group-text bi bi-eye-slash-fill'" v-on:click="chgReveal"></span>
                </div>
            </div>
        </div>

        <div class="row text-start px-4 pb-4">
            <div class="form-group pb-4">
                <label class="pb-2">Nuova Password</label>
                <div class="input-group">
                    <input v-model="user.pwdnew" 
                        :class="!user.pwdnew || !checkPwd?'is-invalid':'is-valid'"
                        :type="reveal?'text':'password'" class="form-control" placeholder="Password"/>
                    <span :class="!reveal ? 'input-group-text bi bi-eye-fill': 'input-group-text bi bi-eye-slash-fill'" v-on:click="chgReveal"></span>
                </div>
            </div>
            <div class="text-start pb-4">
                <div class="form-group">
                    <label class="pb-2">Conferma Password</label>
                    <div class="input-group pb-3">
                        <input v-model="user.pwdnew2" 
                            :class="!user.pwdnew2 || !checkPwd?'is-invalid':'is-valid'"
                            :type="reveal?'text':'password'" class="form-control" placeholder="Password"/>
                        <span :class="!reveal ? 'input-group-text bi bi-eye-fill': 'input-group-text bi bi-eye-slash-fill'" v-on:click="chgReveal"></span>   
                    </div>
                    <button type="submit" :disabled="disableSalva" :class="disableSalva ? 'ms-auto bg-secondary bg-opacity-25 ' : 'ms-auto'" v-on:click.prevent="doCambiaPwd">Salva</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "cambiaPwd",
    components: {},
    props: {
        cambiapwd: Boolean,
    },
    computed: {
        // cambiapassword: function() {
        //     if(this.cambiapwd) return true
        //     return false
        // },
        checkPwdOld: function() {
            if (!this.user.pwdold) return false;
            if (this.user.pwdold!=this.$store.state.userprofile.Password__pc) return false;
            return true;
        },
        checkPwd: function() {
            if (this.user.pwdnew == '') return false;
            if (this.user.pwdnew2 == '') return false;
            if (this.user.pwdnew==this.$store.state.userprofile.Password__pc) return false;
            if (this.user.pwdnew==this.user.pwdnew2) return true;
            return false;
        },
        disableSalva: function() {
            if (this.checkPwdOld && this.checkPwd) return false
            return true
        },
    },
    data: function() {
        return {
            reveal: false,

            user: {
                pwdold:'',
                pwdnew:'',
                pwdnew2:'',
            }
        };
    },
    mounted: function() {},
    methods: {
        chgReveal: function() { this.reveal=!this.reveal },
        doCambiaPwd: function() {
            // if (this.user.pwdnew!=this.user.pwdnew2) return;
            console.log('this.user.pwdnew', this.user.pwdnew)
            this.$store.commit('SET_USERPROFILE_FIELD', { name: 'Password__pc', val: this.user.pwdnew} );
            this.$emit('saveprofilo');
        },
    }
}
</script>