<template>
<!-- nuova -->

<nav class="navbar navbar-light sticky-top shadow mb-4 bg-body">
  <div class="container-fluid">
    <a v-if="$store.getters.logged" class="navbar-brand" @click="closeMenu" href='/#/home'><img class="img-fluid" src="../../public/img/alma-logo-it_4ea26a94.svg" alt="" srcset=""></a>
    <a v-else class="navbar-brand" @click="closeMenu" href='/#/login'><img class="img-fluid" src="../../public/img/alma-logo-it_4ea26a94.svg" alt="" srcset=""></a>
    <div class="d-flex justify-content-end me-3">
        <!-- <i class="bi bi-bell-fill fs-2 text pe-3"></i> -->
        <!-- <button class="navbar-toggler border-0" @click="openMenu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="fs-2 text bi bi-list"></span>
        </button> -->
    </div>
    
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header justify-content-end pe-4 pt-4">
        <!-- <button type="button" class="btn-close btn-close-white pe-4 pt-4 btn-lg opacity-100" @click="closeMenu" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
      </div>

      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">

          <li class="nav-item" v-for="option in $store.getters.abilitazioni" v-bind:key="option.title" v-bind:value="option">
            <a class="nav-link active text-center text-light fs-5" aria-current="page" 
              @click="closeMenu" v-bind:href="option.link" 
            >{{ option.title }}</a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
  export default {
    name: 'NavBar',
    data() {
      return {};
    },
    mounted: function() {},
    methods: {
        closeMenu() {
          const menuCanvas = document.querySelector('#offcanvasNavbar');
          menuCanvas.className = 'offcanvas offcanvas-end'
          menuCanvas.style = 'visibility: hidden;'
        },
        toggleMenu() {
            const menuCanvas = document.querySelector('#offcanvasNavbar');
            menuCanvas.className += ' show'
            menuCanvas.style = 'visibility: visible;'
        },
    }
  }
</script>