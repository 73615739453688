<template>
    <div class="container text-center">
        <div class="row">
            <h1 class="fw-light">Tesi di <span class="fw-bold">{{ name }}</span></h1>
        </div>
        <div class="row">

            <!-- LETTORE PDF -->
            <div class="col-12 px-4 col-lg-6 pe-lg-0 pb-lg-4">
                <div class="row pt-4">
                    <PdfViewer :idFile="idFile" @fileBody="getFileBody"/>
                </div>
            </div>

            <div class="col-12 px-4 pb-4 col-lg-6 ps-lg-5">
                <!-- TEXTAREA PER GIUDIZIO -->
                <div class="row pt-4">
                    <h3 class="fw-bold">Giudizio {{ fase }}</h3>
                    <textarea class="border px-3 pt-2" v-model="giudizioString"></textarea>
                </div>

                <!-- NOTE -->
                <!-- <div class="row pt-3 px-0">
                    <div class="col">
                        <p class="align-self-center text-start mb-0">NOTE / COMMENTI</p>
                        <input class="border px-3" v-model="note"/>
                    </div>
                </div> -->

                <!-- VALUTAZIONE E SALVA -->
                <div class="row pt-4 px-0">
                    <div class="col">
                        <div class="d-flex">
                            <h3 class="fw-bold">Voto: </h3>
                            <select id="voto" class="form-select rounded-pill w-auto ms-3" v-model="voto">
                                <option v-for="v in voti" v-bind:key="v">{{ v }}</option>
                            </select>
                        </div>
                        <p v-if="voto == ''" class="text-danger">Inserisci un voto per salvare</p>
                    </div>
                    <div class="col">
                        <button :class="blockSave ? 'ms-auto bg-secondary bg-opacity-25':'ms-auto'" @click="saveGiudizio()" :disabled="blockSave">Salva</button>
                    </div>
                </div>

                <!-- DOWNLOAD -->
                <div class="row pt-4 mx-5">
                    <button :class="blockDownload ? 'ms-auto bg-secondary bg-opacity-25':'ms-auto'" @click="downloadFile()" :disabled="blockDownload">Download</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PdfViewer from "./PdfViewer.vue";
export default {
    name: 'vediTesi',
    components: {
        PdfViewer
    },
    props: {
        tesi: Object, 
        name: String,
        giudizio: Object,
        info: Object,
        fase: String,
    },

    data() {
        return {
            linkTesi:   '',
            idFile:     '',
            giudizioString:   '',
            voto:       undefined,
            fileBody:   '',
            voti:       [],
            blockSave:  true,
        }
    },

    computed: {
        blockDownload: function() {
            if(this.fileBody != '') return false
            return true
        }
    },

    mounted () {
        this.idFile = this.tesi.idFile__c
        this.linkTesi = 'https://almascuola--dev2021.sandbox.file.force.com/servlet/servlet.FileDownload?file='+ this.idFile
        
        this.giudizioString = this.giudizio.Giudizio__c
        this.voto = this.giudizio.Voto__c

        for(var i = 31; i >= 0; i--) {
            this.voti.push(i)
        }

        console.log('linkTesi', this.linkTesi)
    },

    methods: {
        async getAttachment() {
            this.$store.dispatch('getAttachment', {idFile: this.idFile, parentId:''})
            .then(resp => {
                console.log('getAttachment resp',resp)
                this.fileBody = resp.Body
                this.fileBody = resp
                console.log('body', this.fileBody, typeof(this.fileBody))
            })
        },
        getFileBody(fileBody) {
            this.fileBody = fileBody
            this.blockSave = false
        },
        downloadFile() {
            console.log('--> downloadFile')
            var file = this.fileBody
            var nomeFile = 'Tesi di '+ this.name

            var hrefFile = 'data:application/pdf;base64,' + file

            var a = document.createElement("a");
            a.setAttribute('href', hrefFile);
            a.setAttribute('download', nomeFile);
            a.setAttribute('target', '_blank');
            a.click();
        },

        saveGiudizio: function() {
            console.log('saveGiudizio in vediTesi')
            this.blockSave = true
            const picklist = document.querySelector('#voto');
            if(this.voto != undefined && this.voto != '') {
                var giudizio = {
                    Name: this.name +' - '+ this.$store.state.userprofile.Name +' - '+ this.fase,
                    Giudizio__c : this.giudizioString,
                    Docente__c : this.$store.state.userprofile.Id,
                    Tesi__c : this.tesi.Id,
                    Voto__c : parseInt(this.voto),
                    // Note__c : this.note
                    Tipo__c : this.fase
                }
                if(this.giudizio.Id) giudizio["Id"] = this.giudizio.Id

                console.log('var giudizio', giudizio)
                console.log('info per Sheet', this.info)

                this.$store.dispatch('saveGiudizio', {giudizio: giudizio,  info: this.info})
                .then(resp => {
                    console.log('saveGiudizio resp',resp)
                    this.$emit('updateVoti', this.tesi.Opportunita__c)
                    this.$emit('goBack')
                })
            } else {
                this.voto = ''
                picklist.className += ' border-danger bg-danger bg-opacity-25'
                this.blockSave = false
            }
        },
    }
}
</script>