<template>
    <div class="container text-center col-lg-6">

        <div class="row text-start px-4 pb-4">
            <div class="form-group">
                <label class="pb-2">Nuova Password</label>
                <input v-model="pwd" type="password"
                    class="form-control" placeholder="Nuova Password"/>
            </div>
        </div>

        <div class="row text-start px-4 pb-4">
            <div class="form-group">
                <label class="pb-2">Conferma Password</label>
                <input v-model="pwdconfirm" type="password"
                    class="form-control" placeholder="Conferma Password"/>
            </div>
        </div>

        <div class="row px-4 pb-4">
            <div><button class="mx-auto" type="submit" @click.prevent="savepwd">Salva</button></div>
        </div>

        <div class="row text-center px-4 pb-4">
            <span v-if="response == 'SuccessRequest'" :class="response == 'SuccessRequest'? 'text-success':'text-danger'">{{ response }}</span>
        </div>

    </div>
</template>
  
<script>
export default {
    name: "Recovery",
    components: {},
    data: function() {
        return {
            pwd: '',
            pwdconfirm: '',
            response: ''
        };
    },
    mounted: function() {
        var siteUrl = window.location.href;
        var name = 'tkn';
        name = name.replace(/[[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        var results = regex.exec(siteUrl);
        if (!results) this.$store.state.tkn = '';
        if (!results[2]) this.$store.state.tkn = '';
        this.$store.state.tkn = decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    methods: {
        savepwd: function() {
            this.$store.state.newpwd = this.pwd;
            this.$store.state.newpwdconf = this.pwdconfirm;
            var self = this;
            console.log('Nuova password(this.pwd)', this.pwd)
            this.$store.dispatch('setNewPwd')
            .then(function(resp) {
                console.log(resp);
                self.response = resp;
                if(resp == 'SuccessRequest'){
                    self.$router.push({ name: 'Login' });
                }
            });
        }
    }
}
</script>
  