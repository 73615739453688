<template>
    <div class="container col-lg-6">

        <div class="row text-start px-4 pb-4">
            <div class="form-group">
                <label class="pb-2">Nuova E-mail</label>
                <input v-model="user.emailnew" 
                    :class="checkEmail || !regEmail.test(user.emailnew)?'is-invalid':'is-valid'"
                    required type="email" class="form-control" placeholder="Email"/>
            </div>
        </div>

        <div class="row text-start px-4 pb-4">
            <div class="form-group">
                <label class="pb-2">Conferma E-mail</label>
                <input v-model="user.emailnew2" 
                    :class="checkEmail || !regEmail.test(user.emailnew2)?'is-invalid':'is-valid'"
                    required type="email" class="form-control" placeholder="Email"/>
                <button type="submit" :disabled="checkEmail" :class="checkEmail ? 'ms-auto bg-secondary bg-opacity-25 mt-3' : 'ms-auto mt-3'"
                    @click.prevent="doCambiaEmail">Salva</button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "cambiaEmail",
    components: {},
    props: {},
    computed: {
        checkEmail: function() {
            if (this.user.emailnew == '') return true;
            if (this.user.emailnew2 == '') return true;
            if (this.user.emailnew==this.user.emailnew2) return false;
            return false;
        },
    },
    data: function() {
        return {
            regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            user: {
                emailnew: '',
                emailnew2: '',
            }
        };
    },
    mounted: function() {},
    methods: {
        doCambiaEmail: function() {
            this.$store.commit('SET_USERPROFILE_FIELD', { name: 'PersonEmail', val: this.user.emailnew} );
            this.$emit('saveprofilo');
        },
    }
}
</script>