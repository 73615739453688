<template>
    <div v-if="$store.getters.logged">
        <div class="position-absolute ms-3 mt-0">
            <a v-if="!cambiapassword && !cambiaemail" href="/#/home" class="bi bi-arrow-left-circle-fill fs-2"></a>
            <a v-else @click.prevent="showProfilo()" class="bi bi-arrow-left-circle-fill fs-2"></a>
        </div>

        <div class="container text-center col-lg-6">
            <div class="row pb-4"><h1 class="fw-bold">Profilo Personale</h1></div>

            <div v-if="!cambiapassword && !cambiaemail">
                <div class="row text-start px-4">
                    <div class="form-group pb-2">
                        <label class="pb-2">Indirizzo E-mail</label>
                        <input v-model="$store.state.userprofile.PersonEmail"
                        class="form-control" disabled />
                    </div>
                </div>

                <div class="row text-start px-4 pb-4 border-bottom">
                    <div class="col-3"></div>
                    <div class="col-9">
                        <button class="ms-auto btn-sm" @click.prevent="cambiaemail=true">Cambia E-mail</button>
                    </div>
                </div>

                <div class="row text-start px-4 pb-4 pt-3">
                    <div class="form-group">
                        <label class="pb-2">Username</label>
                        <input v-model="$store.state.userprofile.Username__pc"
                        class="form-control" disabled />
                    </div>
                </div>

                <div class="row text-start px-4">
                    <div class="form-group pb-2">
                        <label class="pb-2">Password</label>
                        <input v-model="$store.state.userprofile.Password__pc"
                        type="password" class="form-control" disabled />
                    </div>
                </div>

                <div class="row text-start px-4 pb-4">
                    <div class="col-3"></div>
                    <div class="col-9">
                        <button class="ms-auto btn-sm" @click.prevent="cambiapassword=true">Cambia Password</button>
                    </div>
                </div>

                <div class="row mt-5">
                    <div><button class="mx-auto"><a class="text-light fs-5" @click="logout" href="/#/Login">Logout</a></button></div>
                </div>
            </div>

            <cambioPwd v-if="cambiapassword" :cambiapwd="cambiapassword" v-on:saveprofilo="saveprofilo($event)"></cambioPwd>
            <cambioEmail v-if="cambiaemail" v-on:saveprofilo="saveprofilo($event)"></cambioEmail>

        </div>
    </div>
</template>
  
<script>
import cambioPwd from '../components/cambioPwd.vue'
import cambioEmail from '../components/cambioEmail.vue'

export default {
    name: "Profilo",
    components: {
        cambioPwd,
        cambioEmail
    },
    data: function() {
        return {
            cambiapassword: false,
            cambiaemail: false,
        };
    },
    methods: {
        showProfilo(){
            this.cambiapassword = false
            this.cambiaemail = false
        },
        saveprofilo: function() {
            this.$store.dispatch('updateProfilo')
            .then(resp => {
                if(resp == null) alert('Errore nel salvataggio! Contattare l\'amministrazione.');
                this.showProfilo()
            });
        },
        logout() {
            this.$store.commit('RESET_USER_DATA');
        },
    }
}
</script>