<template>
    <div class="container">
      <div class="row mt-4">

        <div class="col-12 col-lg-6 border-end">
          <div class="text-center"><h1 class="fw-light">Benvenuto in <span class="fw-bold">ALMA!</span></h1></div>
          <div class="col-6 col-lg-12 mx-auto"><img class="img-fluid" src="../../public/img/Template_Linkedin_Soluta.png"></div>
        </div>
      
        <div class="col-12 col-lg-6 px-4 pb-4">

          <form>
            <h1 class="fw-light">Sei già iscritto?</h1>
            <h1 class="fw-bold">Effettua il login!</h1>

            <div class="mb-3">
              <label class="form-label">Username</label>
              <input v-model="username" type="text" id="username">
            </div>
            <div class="mb-3">
              <label class="form-label">Password</label>
              <input type="password" id="password" v-model="password">
              <span v-if="showerror" class="text-danger">Credenziali errate</span>
            </div>
            <button v-on:click.prevent="doLogin">Accedi</button>
          </form>

          <div class="row py-4">
            <div class="form-group">
              <span v-on:click.prevent="goto">Password dimenticata? <u>Premi qui</u></span>
            </div>
          </div>

        </div>
          
      </div>
    </div>
</template>

<script>
  export default {
    name: 'HomeLog',
    data() {
    return {
      username: '',
      password: '',
      showerror: false,
      showblocked: false
    };
  },
  created() {},
    methods: {
      doLogin: function() {
        var self=this;
        var data={ username: this.username, password: this.password };
        self.showerror=false;
        self.showblocked=false;
        this.$store.dispatch('loginDocenti', data).then(function() {
          if (self.$store.state.connection.userdata && self.$store.state.blocked === false) {
            var data2={ username: self.$store.state.connection.username, crypt: self.$store.state.connection.userdata };
            self.$store.dispatch('getProfile', data2)
            .then(function() {
              console.log('profile recoveder');
              window.location='/#/Home';
              self.$emit('logged');
            });
          } else if(!self.$store.state.connection.userdata && self.$store.state.blocked !== true) {
            self.showerror=true;
          } else if(self.$store.state.blocked === true){
            self.showblocked=true;
          } 
        })
      },
      goto: function() {
        this.$router.push({ name: 'InvioEmailRecovery' });
      }
    }
  }
</script>