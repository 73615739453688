<template>
    <div class="container">

        <!-- ALERT -->
        <div class="alert alert-danger d-none position-fixed w-75 text-center translate-middle-x start-50">
            <div class="row">
                <div class="col"><h4>Accesso negato</h4></div>
                <p v-if="finitiGiurati">Contattare la segreteria studenti.</p>
                <p v-else>
                    Il codice di accesso è scaduto o errato. 
                    <br><span class="text-decoration-underline">Rinquadrare il QR code.</span>
                </p>
            </div>
        </div>

        <div v-if="showBenvenuto" class="row mt-4">

            <div class="col-12 col-lg-6 border-end">
                <div class="text-center"><h1 class="fw-light">Benvenuto in <span class="fw-bold">ALMA!</span></h1></div>
                <div class="col-6 col-lg-12 mx-auto"><img class="img-fluid" src="../../public/img/Template_Linkedin_Soluta.png"></div>
            </div>
        
            <div class="col-12 col-lg-6 px-4 pb-4">

                <form>
                    <h1 class="fw-bold">Inserisci i tuoi dati!</h1>

                    <div class="mb-3">
                        <label class="form-label">Nome e Cognome</label>
                        <input v-model="nomeCognome" type="text" id="nome">
                        <span v-if="showerror" class="text-danger">Compilare tutti i campi.</span>

                    </div>
                    <button @click.prevent="showEsami">Procedi</button>
                </form>

            </div>
            
        </div>
    </div>

  
</template>
  
<script>
export default {

    name: 'LoginEsterni',
    components: {},
    data() {
        return {
            nomeCognome: '',
            showerror: false,
            finitiGiurati: false,
            showBenvenuto: false,
        };
    },
    computed: {
        // showBenvenuto: function() {
        //     if(!this.$store.getters.logged) return false
        //     return true
        // }
    },
    mounted() {
        console.log('url',this.$route.query)
        var self = this

        // controllo che ci sia il parametro necessario
        if(this.$route.query.otp) {
            console.log('otp',this.$route.query.otp)

            // resetto i dati salvati
            // this.$store.commit('RESET_USER_DATA');

            // controllo correttezza OTP
            this.$store.dispatch('checkOTP', {otp: this.$route.query.otp})
            .then(resp => {
                console.log(resp)

                if(resp == 'ok') {

                    // set tavolo
                    this.$store.commit('SET_TAVOLOGIURATO', this.$route.query.tav)

                    // login giurato
                    self.$store.dispatch('getIdGiurato')
                    .then(response => {
                        console.log('repsonse getIdGiurato', response);
                        self.$store.dispatch('loginGiurato', {crypt: response.userdata, token: response.token})
                        .then( resp => {
                            if(resp == null) {
                                this.finitiGiurati = true
                                const alert = document.querySelector('.alert');
                                alert.className = alert.className.replace(' d-none','')
                            } else {
                                console.log('profile Giurato recoveder');
                                self.$emit('logged');
                                this.showBenvenuto = true;
                                window.location='/#/loginesterni';
                            }
                        });
                    }) 
                } else {
                    const alert = document.querySelector('.alert');
                    alert.className = alert.className.replace(' d-none','')
                }
                
            })
        }
        if(this.$store.state.nomeGiurato != '') this.nomeCognome = this.$store.state.nomeGiurato
    },
    methods: {
        showEsami() {
            if(this.nomeCognome == '') this.showerror = true
            else {
                console.log('mostraEsami')
                this.$store.commit('SET_NOMEGIURATO', this.nomeCognome.toUpperCase())

                var a = document.createElement("a");
                a.setAttribute('href', '/#/Esami');
                a.setAttribute('target', '_top');
                a.click();
            }
        }
    }

}
</script>