<template>
    <div>
        <div class="position-absolute ms-3 mt-0">
            <a href="/#/login" class="bi bi-arrow-left-circle-fill fs-2"></a>
        </div>

        <div class="container text-center col-lg-6">
            <div class="row pb-4"><h1 class="fw-bold">Profilo Personale</h1></div>

            <div class="row text-start px-4 pb-4">
                <div class="form-group">
                    <label class="pb-2">E-mail</label>
                    <input v-model="$store.state.emailRecovery" type="text"
                        class="form-control" placeholder="Email"/>
                </div>
            </div>

            <div class="row px-4 pb-4">
                <div><button class="mx-auto" type="submit" @click.prevent="sendEmail">Invia</button></div>
            </div>

            <div class="row text-center px-4 pb-4">
                <span v-if="showerror" class="text-danger">User non trovato, contatta <a href="mailto:iscrizioni@scuolacucina.it">iscrizioni@scuolacucina.it</a></span>
                <span v-if="showsuccess" class="text-success">Richiesta inviata! Riceverai una mail.</span>
            </div>

        </div>
    </div>
</template>
  
<script>
export default {
    name: 'InvioEmailRecovery',
    components: {},
    data: function () {
        return {
            showerror: false,
            showsuccess: false,
        };
    },
    methods: {
        sendEmail: function(){
            var self = this;
            this.$store.dispatch('doRecover')
            .then(function(resp) {
                console.log(resp);
                if(resp == 'no'){
                    self.showerror = true;
                    self.showsuccess = false;
                }
                if(resp == 'ok'){
                    self.showerror = false;
                    self.showsuccess = true;
                }
            });
        }
    }
}
</script>